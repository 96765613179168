import type { IRuleState, IStep, IStepGroupResponse } from "typings/modules"
import { IAPIResponse } from "models/api"
import { ICustomerGroup, ISummarizedCustomerGroup } from "modules/Customers/models/customerGroup"
import { IProduct } from "modules/Products/models/product"
import { CalculatorStepGroupType, OperationTypes } from "constants/calculator"
import { IAuditInformation } from "models/audit"
import { IBulkErrorMessage } from "models/bulk"
import { BULK_STATUS } from "constants/bulk"
import { ISelectOption } from "components"
import { GridFilterItem, GridSortItem } from "@mui/x-data-grid"

export interface ICalculatorTableResponse extends IAPIResponse {
  content: ICalculatorTable[]
}

export interface IStepGroupTypeResponse extends IAPIResponse {
  content: IStepGroupType[]
}

export interface IAPIRulesResponse extends IAPIResponse {
  content: IAPIRuleResponse[]
}

export interface IAPIRuleRequest {
  id?: number
  order: number
  name: string
  created?: string
  output?: string
  operation: IRuleOperationRequest
}

export interface IAPIRuleResponse {
  id: number
  order: number
  name: string
  created?: string
  output?: string
  operation: IRuleOperationResponse
}

export interface IAPIStepsResponse extends IAPIResponse {
  content: IStep[]
}

export interface IAPICalculatorResults extends IAPIResponse {
  content: IAPICalculatorResult[]
}

export interface IStepGroupResultRequest {
  id: number
  price: string
}

export interface IAPICalculatorResult {
  product: IProduct
  customerGroups: ICustomerGroup[]
}

export interface ICalculatorResultResponse extends IAPIResponse {
  content: IAPICalculatorResult[]
}

export interface ICalculatorRow {
  id: string
  product: IProduct
  customerGroup: ICustomerGroup
}

export interface IResultRows {
  id?: string
  productId: IProduct["id"]
  productIds: string
  ecomname: string
  customerGroups: string
  customerGroupId: ICustomerGroup["id"]
  vcost: number
  wholesalePrice?: string
  retailPrice?: string
  vetPrice?: string
  fee?: string
  aqCost?: string
  manufacturer: string
  specieName: string
  majorCategoryName: string
}

export interface ICalculatorResultTable {
  name: string
  vsId: string
  customerGroupName: string
  type: string
  result: string
}

export interface IStepGroupType {
  id: number
  name: CalculatorStepGroupType
  displayName: string
  masterType: boolean
}

export interface IOperationInputRequest {
  id?: number
  order: number
  value: string
  inputType: OperationTypes
}

export interface IOperationInputResponse {
  id: number
  order: number
  value: string
  inputType: OperationTypes
}

export const LabelToOperationTypeMap = {
  Input: OperationTypes.INPUT,
  "List of Rules": OperationTypes.RULE,
  "List of Steps": OperationTypes.STEP,
} as const

export const OperationTypeToLabelMap = {
  [OperationTypes.STRING]: OperationTypes.INPUT,
  [OperationTypes.NUMBER]: OperationTypes.INPUT,
  [OperationTypes.RULE]: "List of Rules",
  [OperationTypes.STEP]: "List of Steps",
} as const

export interface IRuleOperationRequest {
  id?: number
  name: string
  inputs: IOperationInputRequest[]
}

export interface IRuleOperationResponse {
  id: number
  name: string
  inputs: IOperationInputResponse[]
}

export interface IStepGroupRow {
  id: number
  steps: IStepRow[]
}

export interface IStepRow {
  id: number
  rules: IRuleRow[]
}

export interface IRuleRow {
  id?: number
  ruleName: string
  description?: string
  relatedRules: number
  dependence: string
  created?: string
}

export interface IAPIStepRequest {
  id?: number
  order: number
  name: string
  rules: IAPIRuleRequest[]
  cycleReference: boolean
}

export interface IAPIStepGroupRequest {
  id?: number
  name: string
  type: string
  created?: string
  description?: string
  steps: IAPIStepRequest[]
}

export interface IAPICalculatorRequest {
  id?: number
  name: string
  description?: string
  created?: string
  lastUpdated?: string
  stepGroups: IAPIStepGroupRequest[]
  customerGroups: ISummarizedCustomerGroup[]
  productQty?: number
  status: "ACTIVE" | "INACTIVE"
  tier: string
}

export interface IAPICalculatorResponse {
  id: number
  name: string
  description?: string
  lastModifiedBy?: string
  createdBy?: string
  created?: string
  stepGroups: IStepGroupResponse[]
  customerGroups: ISummarizedCustomerGroup[]
  productQty?: number
  status: "ACTIVE" | "INACTIVE"
  tier: string
  lastUpdate: string
}

export interface IApiCalculationResultRequestBody {
  calculatorId: string
  page: number
  size: number
  calculationId?: number
  sortModel?: GridSortItem[]
  filterValue?: GridFilterItem
  switchKey?: "atypical" | "error" | "retailLessThanMap" | "retailLessThanVetPrice"
}

export interface ICalculatorTable {
  id?: number
  name?: string
  description?: string
  created?: string
  lastUpdated?: string
  customerGroups: string
  productQty?: number
  status: string
}

export interface ICalculation {
  id: number
  created: string
}

export interface IPublicationDataProduct {
  productId: IProduct["id"]
  customerGroupId: ICustomerGroup["id"]
}

export interface IPublicationData {
  resultId: number
  productIdList: IPublicationDataProduct[]
  publicationDate: string
  userId: number
  forcePublication: boolean
  calculatorId: number
}

export interface IPublicationPriceParams {
  current?: boolean
  page: number
  size: number
  sort: string
  dir: "ASC" | "DESC"
}

export interface IStepGroupPublication {
  id: number
  type: CalculatorStepGroupType
  price: string
  current: boolean
  calculatorName: string
  userName: string
  created: string
  publicationDate: string
  publicationStatus: string
}

export interface IPublicationProduct {
  ecomname: string
  id: number
  majorCategoryName: string
  manufacturer: string
  name: string
  size: string
  specieName: string
  supplierName: string
  vsId: string
  vcost: string
}

export interface IPublicationCustomerGroup {
  fullName: string
  id: number
  name: string
  stepGroupResults: IStepGroupPublication[]
  vsGroupId: number
}

export interface IPublicationResponse {
  product: IPublicationProduct
  customerGroups: IPublicationCustomerGroup[]
  publicationDate: string
}

export interface IDebugRule {
  id: number
  name: string
  price: string
}

export interface IDebugStep {
  id: number
  name: string
  price: string
  rules: IDebugRule[]
}

export interface IDebugStepGroup {
  id: number
  name: string
  price: string
  type: string
  steps: IDebugStep[]
}

export interface IDebugResult {
  id: number
  name: string
  description: string | null
  stepGroups: IDebugStepGroup[]
}

export interface ITreeView {
  nodeId: string
  label: string
  sublistArray?: ITreeView[]
}

export interface IStepExportResponse {
  product: {
    id: number
    name: string
    vsId: string
  }
  result: string
}

export interface IStepExport {
  id: number
  name: string
  vsId: string
  result: string
}

export enum ICalculateType {
  CALCULATOR = "Calculator",
}

export interface ICalculateResponse {
  errorMessages: IBulkErrorMessage[]
  errorQuantity: number
  savedQuantity: number
  startTime: string
  status: BULK_STATUS
  type: ICalculateType
  uuid: string
}

export interface ICalculateEventResponse {
  excluded: number[]
  executed: EventResponse[]
}

export type EventResponse = {
  id: string
  calculatorId: number
  type: string
  createdTime: string
  status: string
}

export interface ICalculateEventRequest {
  calculators: number[]
}

export interface ICalculatorsAuditInformation {
  createdBy: string | null
  created: string
  lastModifiedBy: string
  lastUpdate: string
  id: number
  name: string
  description: string
  status: string
  stepGroups: string[]
  customerGroups: string[]
}

export type ICalculatorsAudit = [ICalculatorsAuditInformation, IAuditInformation, string, string[]]

export type PublishMode = "publish-all" | "publish-selected" | "force-publication" | "export-all" | "export-selected"

export interface IUpdateStepRequestPayload {
  calculatorId: number
  stepGroupId: number
  step: IStep
}

export interface IFormatStepRulesParams extends IUpdateStepRequestPayload {
  rules: IRuleState[]
  systemObjects: ISelectOption[]
  /**
   * `Rule Id`
   */
  id: number
  mode: "create" | "update" | "delete"
}

export interface IMakeDebugRequestPayload {
  calculatorId: string
  productId: string
  customerGroupId: string
  showAll: boolean
}
