import * as React from "react"
import { SkuListDialog } from "./SkuListDialog"
import { IAPICalculatorResult } from "../models/calculator"

export type RetailLessThanMapWarnDialogProps = {
  warnSkuList: IAPICalculatorResult[]
  warnSkuTotal: number
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onContinue: () => void
}

const RetailLessThanMapWarnDialog = (props: RetailLessThanMapWarnDialogProps) => {
  const { warnSkuList, warnSkuTotal, isOpen, isLoading, onClose, onContinue } = props
  const priceCount = warnSkuList.reduce((total, product) => {
    return total + product.customerGroups.length
  }, 0)
  const subText = (
    <>
      <p>WARNING: Retail prices found less than MAP. Do you want to continue publishing?</p>
      <p>
        Displaying {priceCount} of {warnSkuTotal} total noncompliant results
      </p>
    </>
  )

  return (
    <SkuListDialog
      priceList={warnSkuList}
      title="Retail Price vs MAP validation"
      loadingTitle="Validating retail prices against MAP..."
      subText={subText}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onContinue={onContinue}
    />
  )
}
export { RetailLessThanMapWarnDialog }
