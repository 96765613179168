import * as React from "react"
import { Dialog, List } from "components"
import { ListItemText, CircularProgress } from "@material-ui/core"
import { IAPICalculatorResult } from "../models/calculator"

export type skuListDialogProps = {
  priceList: IAPICalculatorResult[]
  title: string
  loadingTitle: string
  subText: JSX.Element
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onContinue: () => void
}

const SkuListDialog = (props: skuListDialogProps) => {
  const { priceList, loadingTitle, isOpen, isLoading, onClose, onContinue } = props

  let { title, subText } = props

  title = isLoading ? loadingTitle : title

  subText = isLoading ? null : subText

  const continueButtonAction = isLoading ? null : onContinue

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={title}
      dialogContentText={subText}
      secondaryActionLabel="Cancel"
      primaryActionLabel="Continue"
      primaryActionHandler={continueButtonAction}
      secondaryActionHandler={onClose}
      maxWidth="xs"
      fullWidth
    >
      {isLoading ? (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress
            size={80}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "2em" }}
          />
        </div>
      ) : (
        <List>
          {priceList.map(sku => (
            <ListItemText key={sku.product.vsId}>
              {sku.product.vsId} (
              {sku.customerGroups
                .map(customerGroup => customerGroup.name)
                .sort()
                .join(", ")}
              )
            </ListItemText>
          ))}
        </List>
      )}
    </Dialog>
  )
}
export { SkuListDialog }
